<div class="container" title="Edit Access Token">
  <div *ngIf="(pixelManagerEditService.inEditMode$|ngrxPush) !== rowIndex" class="one-row">
    <svg *ngIf="!(pixelManagerEditService.inEditMode$|ngrxPush)" role="img" (click)="$event.stopPropagation();edit();">
      <use xlink:href="assets/img/sprites.svg#partner-pencil-icon" />
    </svg>
    <span>
      {{ form.controls.token.value }}
    </span>
  </div>
  <div
    *ngIf="((pixelManagerEditService.inEditMode$|ngrxPush)||(pixelManagerEditService.inEditMode$|ngrxPush)===0) && ((pixelManagerEditService.inEditMode$|ngrxPush) === rowIndex)">
    <app-edit-access-token-input [token]="form.controls.token.value" (editRequested)="editRequested($event)"
      (cancelEdit)="cancelEditMode()">
    </app-edit-access-token-input>
  </div>
</div>

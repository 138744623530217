<div class="documentation tab-content">
  <div>
    System1 supports adding conversion tracking pixels to your landing pages. By specifying
    the ad network pixel ID and event you&apos;d like to track via our URL parameters,
    we will embed your pixel conversion pixel code on your campaigns. You can use these
    parameters to track conversions when users land or execute a search for advertisers on our campaigns.
  </div>
  <br />
  <div>
    Set up server-to-server conversion tracking through the S2S Pixel Manager, located under "Self-serve Tools."
    Within the manager, select your buyside, input the pixel ID, and provide the corresponding buyside access token.
    This process is specifically required for Facebook and Tiktok campaigns.
    Once mapped in the manager, we will fire conversions server-side.
  </div>
  <br />
  <div>
    The following ad networks are supported as of this release:
    <ul>
      <li *ngFor="let network of adNetworks">{{ network }}</li>
    </ul>
  </div>
  <br />
  <h3 class="documentation-sub-header-2">Buyside Tracking Parameters</h3>
  <div>
    You must specify the id and conversion name in order to fire your pixel event(s).
    The conversion name within the domain must be an exact match to the event name within the corresponding buyside platform.
  </div>
  <br />
  <div class="documentation-grid-container">
    <div *ngFor="let header of table.headers; index as i" [class]="getColumnHeaderClass(table, i)">
      <span class="documentation bold">{{ header }}</span>
    </div>
    <ng-container *ngFor="let element of table.data">
      <br />
      <div class="documentation-grid section-header">
        <span class="documentation bold">{{ element.header }}</span>
      </div>
      <div
        class="documentation-grid section-header section-header sub-header"
        *ngIf="!ovs.isNullOrEmpty(element.subHeader) || !ovs.isNullOrEmpty(element.required)">
        <span *ngIf="!ovs.isNullOrEmpty(element.subHeader)">
          {{ element.subHeader }}
          <br /><br />
        </span>

        <span *ngIf="!ovs.isNullOrEmpty(element.required)">
          <span class="documentation bold required-fields">Required: </span>
          <span [innerHTML]="boldParameters(element.required, element.records)"></span>
          <br /><br />
        </span>
      </div>
      <ng-container *ngFor="let record of element.records">
        <div [class]="getColumnValueClass(0, table)">
          <span class="documentation bold">{{ record.parameter }}</span>
        </div>
        <div [class]="getColumnValueClass(1, table)">
          <span>{{ record.description }}</span>
        </div>
        <div [class]="getColumnValueClass(2, table)">
          <span>
            <span class="documentation bold">{{ record.parameter }}</span
            >{{ record.example }}
          </span>
        </div>
        <div [class]="getColumnValueClass(3, table)">
          <span>
            {{ record.where }}
          </span>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <br />
</div>

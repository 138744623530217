import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ColumnInfo } from 'core-global-frontend-grid';

@Injectable({
  providedIn: 'root',
})
export class PixelManagerAccessTokenHistoryColumnBuilderService {
  constructor(
    private _datePipe: DatePipe,
  ) { }

  columns(partners): ColumnInfo[] {
    let columns = [{
      field: 'created_at',
      label: 'Creation date',
      subtype: 'date',
      transform: (value): any =>
        this._datePipe.transform(value, 'yyyy-MM-dd'), sortable: true,
    }] as ColumnInfo[];
    if (partners.length > 0) {
      columns.push({
        field: 'partnerName',
        label: 'Partner',
        sortable: true,
      });
    }

    columns = [...columns,
    {
      field: 'buyside',
      label: 'Buyside Network',
      sortable: true,
    },
    {
      field: 'identifier',
      label: 'Pixel ID',
      sortable: true,
    },
    {
      type: 'edit-access-token',
      field: 'token',
      toolTip: 'Click to edit the access token',
      label: 'Access Token',
      sortable: true,
    }
    ];
    return columns;
  }
}

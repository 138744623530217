import { Component } from '@angular/core';
import { BasePageComponent, ILayoutContent } from '@nimbus/shared-lib';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { PixelManagerAccessTokenHistoryColumnBuilderService } from '../services/pixel-manager-access-token-history-column-builder.service';
import { PixelManagerCacheService } from '../services/pixel-manager-cache.service';
import { GridModel } from 'core/libs/global/frontend/grid/src/lib/grid-models/grid-model';
import { PageEvent } from '@angular/material/paginator';
import { PartnerCacheService } from '../../../shared/services/partner-cache.service';
import { Sort } from '@angular/material/sort';
import { PixelManagerEditService } from '../services/pixel-manager-edit.service';

@Component({
  selector: 'app-pixel-manager-history',
  templateUrl: './pixel-manager-history.component.html',
  styleUrls: ['./pixel-manager-history.component.scss'],
})
export class PixelManagerHistoryComponent
  extends BasePageComponent
  implements ILayoutContent {
  private pageOptionsSubject = new BehaviorSubject<PageEvent>({pageIndex: 0, pageSize: 20, length: undefined});
  private pageSortSubject = new BehaviorSubject<Sort>({active: 'created_at', direction: 'desc'});
  private forceLoadSubject = new BehaviorSubject<boolean>(true);
  pageOptions$ = this.pageOptionsSubject.asObservable();
  private _pageSort$ = this.pageSortSubject.asObservable();
  private _forceLoad$ = this.forceLoadSubject.asObservable();
  emailID: string;
  gridModel$: Observable<GridModel> = combineLatest([
    this.pageOptions$.pipe(distinctUntilChanged((a, b) => a.pageIndex === b.pageIndex && a.pageSize === b.pageSize)),
    this._pageSort$.pipe(distinctUntilChanged((a, b) => a.active === b.active && a.direction === b.direction)),
    this._forceLoad$,
  ]).pipe(
    switchMap(([pageEvent, pageSort, _]) => combineLatest([this.pixelManagerCacheService.getPixelAccessToken(pageEvent, pageSort), of(pageSort)])),
    switchMap(([_, pageSort]) => combineLatest([this.pixelManagerCacheService.pixelAccessTokenHistory$, of(pageSort)])),
    tap(([pixelTokens, _]) => (this._pixelManagerEditService.currentPixelAccessToken=pixelTokens['pixel_token_mappings'])),
    map(([pixelTokens, pageSort]) => new GridModel("pixel-manager", pixelTokens['pixel_token_mappings'], pageSort, pixelTokens['pages']*pixelTokens['per_page']))
  );
  columns$: any;

  constructor(
    public pixelManagerCacheService: PixelManagerCacheService,
    _partnerCacheService: PartnerCacheService,
    private _pixelManagerEditService: PixelManagerEditService,
    private _pixelAccessTokenHistoryColumnBuilderService: PixelManagerAccessTokenHistoryColumnBuilderService,
  ) {
    super();
    this.columns$ = _partnerCacheService.partners$.pipe(
      map(partners => this._pixelAccessTokenHistoryColumnBuilderService.columns(partners))
     );
     this._pixelManagerEditService.reloadPixelAccessToken$.subscribe(() => {
      this.forceLoadSubject.next(true);
     });
  }

  pageChanged(pageEvent: PageEvent) {
    this.pageOptionsSubject.next(pageEvent);
  }

  sortChanged(sort: Sort) {
    this.pageOptionsSubject.next({ pageIndex: 0, pageSize: this.pageOptionsSubject.value?.pageSize || 20, length: this.pageOptionsSubject.value?.length });
    this.pageSortSubject.next(sort);
  }
}

import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { PartnerCacheService } from '../../../shared/services/partner-cache.service';
import { PixelAccessTokenModel } from '../models/pixel-access-token.model';
import { PixelManagerSchemaService } from '../schemas/pixel-manager-schema.service';
import { PixelManagerEditService } from '../services/pixel-manager-edit.service';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { PixelManagerCacheService } from '../services/pixel-manager-cache.service';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';
import { PixelManagerBuysideNetworkService } from '../../../shared/services/pixel-manager-buyside-network.service';

@Component({
  selector: 'app-pixel-manager-form',
  templateUrl: './pixel-manager-form.component.html',
  styleUrls: ['./pixel-manager-form.component.scss'],
})
export class PixelManagerFormComponent implements OnDestroy {
  form = new FormGroup({});
  model = {};
  options: FormlyFormOptions = {};
  fields = [];
  private readonly _subscriptionManager = new SubscriptionManager();

  protected readonly _apiCallInProgressSubject = new BehaviorSubject<boolean>(
    false,
  );
  readonly apiCallInProgress$: Observable<boolean> =
    this._apiCallInProgressSubject.asObservable();

  constructor(
    public _pixelManagerSchemaService: PixelManagerSchemaService,
    private _pixelManagerBuysideNetworkService: PixelManagerBuysideNetworkService,
    private _snackbarService: SnackbarService,
    private _pixelManagerCacheService: PixelManagerCacheService,
    private _webServiceBuilderService: NimbusWebServiceBuilderService,
    private _partnerCacheService: PartnerCacheService,
    public pixelManagerEditService: PixelManagerEditService,
  ) {
    this._subscriptionManager.register(
      this._partnerCacheService.partners$
        .pipe(tap(partners => (this.fields = this._pixelManagerSchemaService.getSchema())))
        .subscribe(),
    );
  }

  submitRequest(): void {
    this._snackbarService.open(
      'Processing your request.',
      SnackBarType.working,
      3000,
    );
    this._apiCallInProgressSubject.next(true);
    this._subscriptionManager.register(
      this._pixelManagerBuysideNetworkService.buysideNetworks$
        .pipe(
          switchMap(networks => this._webServiceBuilderService.builder
            .withApi('PartnerAPI')
            .withUrl(`pixel-tokens?partnerId=${this.form.value['pixelAccessTokenRequest'].partnerId ?? this._partnerCacheService.partners[0].id}`)
            .build()
            .create(new PixelAccessTokenModel(
              networks.find(n => n.id === this.form.value['pixelAccessTokenRequest'].buysideNetwork).buysideNetwork.toLowerCase(),
              this.form.value['pixelAccessTokenRequest'].pixelId,
              this.form.value['buysideAccessToken']
            ))),
          switchMap(() => this._pixelManagerCacheService.getPixelAccessToken()),
          tap(() => this._snackbarService.open(
              'Pixel access token created successfully.',
              SnackBarType.done,
              3000,
            )),
          tap(() => this.pixelManagerEditService.reloadPixelAccessToken()),
          tap(() => this.options.resetModel()),
          tap(() => this._apiCallInProgressSubject.next(false)),
          catchError(err => {
            this._snackbarService.open(
              err?.error?.message ?? err.message ?? 'There was an error creating this mapping.  Please try adding a new Access Token or contact your Account Manager if you continue to see this error.', SnackBarType.error);
            this._apiCallInProgressSubject.next(false);
            return EMPTY;
          })
        )
        .subscribe(),
      'CreateAccessTokenRequest',
    );
  }

  canSubmit(): boolean {
    return this.form.valid;
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
  }
}

import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PixelAccessTokenHistoryResponse } from '../models/pixel-access-token-history.model';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';
import { PartnerCacheService } from '../../../shared/services/partner-cache.service';
import { PageEvent } from '@angular/material/paginator';
import { TitleCasePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Sort } from '@angular/material/sort';

@Injectable({
  providedIn: 'root',
})
export class PixelManagerCacheService {
  isLoading$: Observable<boolean>;
  private _pixelAccessTokenHistorySubject = new ReplaySubject<PixelAccessTokenHistoryResponse>();
  pixelAccessTokenHistory$: Observable<PixelAccessTokenHistoryResponse> = this._pixelAccessTokenHistorySubject.asObservable();

  constructor(
    private _webServiceBuilderService: NimbusWebServiceBuilderService,
    private _partnerCacheService: PartnerCacheService
  ) { }

  getPixelAccessToken(pageEvent?: PageEvent, pageSort?: Sort): Observable<PixelAccessTokenHistoryResponse> {
    this.isLoading$ = of(true);
    return this._partnerCacheService.partners$.pipe(
      switchMap(partners => {
        let params = new HttpParams();
        partners.forEach(partner => {
          params = params.append('partnerIds', partner.id.toString());
        });
        if (pageEvent) {
          params = params.set('page', (pageEvent.pageIndex + 1).toString());
          params = params.set('pageSize', pageEvent.pageSize.toString());
        }
        if (pageSort) {
          if (pageSort.active === 'partnerName') {
            pageSort.active = 'partner_id';
          }
          params = params.set('sortBy', pageSort.active);
          params = params.set('sortDirection', pageSort.direction);
        }
        return combineLatest([
           this._webServiceBuilderService.builder
          .withApi('PartnerAPI')
          .withUrl('pixel-tokens')
          .build()
          .get<PixelAccessTokenHistoryResponse>(params),
          of(partners),
        ]);
      }),
      map(([response, partners]) => {
        response.pixel_token_mappings = response.pixel_token_mappings.map(mapping => ({
          ...mapping,
          buyside: (new TitleCasePipe()).transform(mapping.buyside),
          partnerName: partners.find(partner => partner.id === mapping.partner_id).name,
        }));
        return response;
      }),
      tap(response => this._pixelAccessTokenHistorySubject.next(response)),
      tap(() => (this.isLoading$ = of(false))),
      catchError(error => {
        this.isLoading$ = of(false);
        throw error;
      }),
    );
  }
}

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BuysideNetworkModel } from '../../areas/pixel-manager/models/buyside-networks.model';

@Injectable({
  providedIn: 'root',
})
export class PixelManagerBuysideNetworkService {
  get buysideNetworks$(): Observable<BuysideNetworkModel[]> {
    return of([
      { id: 1, buysideNetwork: 'Facebook' },
      { id: 2, buysideNetwork: 'TikTok' },
      { id: 3, buysideNetwork: 'Snapchat' }]);
  }
}

import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { tap } from 'rxjs/operators';
import { PixelManagerEditService } from '../../services/pixel-manager-edit.service';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ColumnInfo } from 'core-global-frontend-grid';
import {
  AngularMaterialModule,
  NimbusFormsModule,
} from 'core-global-frontend-common-ui';
import { ConfirmationService } from '@nimbus/shared-lib';
import { CommonModule } from '@angular/common';
import { EditAccessTokenInputComponent } from './edit-access-token-input/edit-access-token-input.component';
import {
  ICustomCell,
  IClickableCell,
} from 'core/libs/global/frontend/grid/src/lib/cells/icell-component';
import { PushPipe } from '@ngrx/component';

@Component({
  selector: 'edit-access-token',
  templateUrl: './edit-access-token.component.html',
  styleUrls: ['./edit-access-token.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AngularMaterialModule,
    NimbusFormsModule,
    ReactiveFormsModule,
    EditAccessTokenInputComponent,
    FormsModule,
    PushPipe
  ],
})
export class EditAccessTokenComponent
  implements ICustomCell, IClickableCell {
  @Input() form: FormGroup<any>;
  @Input() column: ColumnInfo;
  @Input() editable: boolean;
  @Input() rowIndex: number;
  @Input() transform?: (input: string) => string;
  @Input() dataSource: MatTableDataSource<FormGroup<any>, MatPaginator>;
  @Output() clickEvent = new EventEmitter<ICustomCell>();

  constructor(
    private _snackbarService: SnackbarService,
    public pixelManagerEditService: PixelManagerEditService,
    private _confirmationService: ConfirmationService
  ) { }

  edit() {
    this.pixelManagerEditService.updateEditState(this.rowIndex, true);
  }

  submitEdit(editedAccessToken: string) {
    this._snackbarService.open(
      'Processing your request.',
      SnackBarType.working,
      3000,
    );
    this.pixelManagerEditService.updateEditState(this.rowIndex, false);
    this.pixelManagerEditService.updatePixelAccessToken(this.rowIndex, editedAccessToken);
  }

  cancelEditMode() {
    this.pixelManagerEditService.updateEditState(this.rowIndex, false);
  }

  editRequested(accessToken: string) {
    return this._confirmationService.showAsync(
      'Warning',
      'Continue',
      null,
      null,
      true,
      true,
      null,
      null,
      'This edit will break conversion events leveraging the original mapping',
      true
    ).pipe(
      tap(confirm => {
        if (confirm) {
          this.submitEdit(accessToken);
        }
        else {
          this.cancelEditMode();
        }
      }),
    ).subscribe();
  }
}

<div class="documentation tab-content">
  <div>
    <b></b>
    The System1 Reporting API provides access to data in your account.
    <span class="documentation bold">
      For instructions on how to access these API endpoints, please
    </span>
    <a 
      href="https://docs.google.com/document/d/e/2PACX-1vRz0bLhpa8H1CdtBta02E218ujaYcXu507wzNnwlYQUq9BCNTYw1XZnvpCNf7NQ5uk5YxNlmnwVEHpp/pub"
      class="documentation-link"
      target="_blank"
    >
    <span class="documentation-link-text">click here</span>
    </a>.
    Please note multiple steps are required to retrieve data from these endpoints so review the instructions carefully.
  </div>

  <br />

  <div>
    We offer various API endpoints depending on the granularity of data you are seeking,
    but the two main levels are summary which is going to contain finalized data from the previous day
    and intraday which is going to have estimates for the current day by hour.
    We also have a keyword endpoint that has estimates of your keyword data. All reporting is in UTC timezone.
  </div>

  <br />

  <h3 class="documentation-sub-header-2">Keyword Endpoint</h3>
  <mark class="red-highlight">
    https://reports.system1.com/partner/v1/report?report_type=syndication_afd_keyword_daily&days=14&date=2024-01-10&auth_key=AUTH_KEY_REDACTED
  </mark>

  <div class="documentation shifted">
    <span class="documentation bold">Keyword Report:</span> Provides daily searches, clicks,
    and estimated revenue by date, domain, vertical, keyword, country, and device type.
  </div>

  <br />

  <h3 class="documentation-sub-header-2">Summary Endpoints</h3>

  <mark class="red-highlight">
    https://reports.system1.com/partner/v1/report?report_type=syndication_afd_31_day_summary&days=31&date=2024-01-10&auth_key=AUTH_KEY_REDACTED
  </mark>
  <div class="documentation shifted">
    <span class="documentation bold">31 Day Summary Report:</span> Provides the same data you can find in the
    <span class="documentation bold">31 Day Summary</span> tab in the emailed reports.
    Here you can find account-level metrics by day for the last 31 completed days and daily adnetwork adjustments.
  </div>

  <mark class="red-highlight">
    https://reports.system1.com/partner/v1/report?report_type=domain_subid&days=14&date=2024-01-10&auth_key=AUTH_KEY_REDACTED
  </mark>
  <div class="documentation shifted">
    <span class="documentation bold">Domain Subid Report:</span> Provides daily sessions, searches,
    clicks and estimated revenue by date, domain, and subid for PAST days
  </div>

  <br />

  <h3 class="documentation-sub-header-2">Hourly / Intraday Endpoints</h3>
  <div>
    The following endpoints provide a cumulative hourly estimate of your traffic and revenue for each System1 campaign domain.
    <span class="documentation bold">Caution:</span> 
    <i>
      clicks and revenue are frequently discounted by our ad network partners in real-time,
      so this endpoint may overcount your daily totals.
    </i>
  </div>

  <mark class="red-highlight">
    https://reports.system1.com/partner/v1/report?report_type=domain_subid_estimated_hourly&days=14&date=2024-01-10&auth_key=AUTH_KEY_REDACTED
  </mark>
  <div class="documentation shifted">
    <span class="documentation bold">Domain Subid Hourly Report:</span> Provides a <u>non-cumulative</u> estimate of hourly searches,
    clicks, and revenue by domain and subid based on the hour the events occurred.
  </div>

  <mark class="red-highlight">
    https://reports.system1.com/partner/v1/report?report_type=domain_subid_estimated_intraday&days=14&date=2024-01-10&auth_key=AUTH_KEY_REDACTED
  </mark>
  <div class="documentation shifted">
    <span class="documentation bold">Domain Subid Intraday Report:</span> Provides a <u>cumulative</u> estimate of hourly searches,
    clicks, and revenue by domain and subid up to the latest available hour.
  </div>

  <br />


</div>

import { Injectable, OnDestroy } from '@angular/core';
import { SubjectManager, SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { Observable } from 'rxjs';
import { EMPTY } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { PixelManagerCacheService } from './pixel-manager-cache.service';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';
import { PixelAccessTokenModel } from '../models/pixel-access-token.model';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';

@Injectable()
export class PixelManagerEditService implements OnDestroy {
  private _subscriptionManager = new SubscriptionManager();
  private readonly _subjectManager = new SubjectManager();
  currentPixelAccessToken: any[] = [];
  private _currentActiveId: number;
  get inEditMode$(): Observable<number | undefined> {
    return this._subjectManager.get('inEditMode');
  }
  get reloadPixelAccessToken$(): Observable<boolean> {
    return this._subjectManager.get('reloadPixelAccessToken');
  }

  constructor(
    private _pixelManagerCacheService: PixelManagerCacheService,
    private _snackbarService: SnackbarService,
    private _webServiceBuilderService: NimbusWebServiceBuilderService,
  ) {
    this._subjectManager.register('inEditMode');
    this._subjectManager.register('reloadPixelAccessToken');
  }

  ngOnDestroy(): void {
    this._subjectManager.clear();
  }

  updateEditState(id: number, state: boolean) {
    if (!this._currentActiveId && state) {
      this._currentActiveId = id;
      this._subjectManager.next('inEditMode', id);
    } else if (id === this._currentActiveId && !state) {
      this._currentActiveId = undefined;
      this._subjectManager.next('inEditMode', undefined);
    }
  }

  updatePixelAccessToken(id: number, accessToken: string) {
    this._subscriptionManager.register(
      this._webServiceBuilderService.builder
        .withApi('PartnerAPI')
        .withUrl(`pixel-tokens?partnerId=${this.currentPixelAccessToken[id].partner_id}`)
        .build()
        .save(new PixelAccessTokenModel(
          this.currentPixelAccessToken[id].buyside.toLowerCase(),
          this.currentPixelAccessToken[id].identifier,
          accessToken
        ))
        .pipe(
          switchMap(() => this._pixelManagerCacheService.getPixelAccessToken()),
          tap(() => this._snackbarService.open('Pixel access token updated successfully.', SnackBarType.done, 3000,)),
          tap(() => this.reloadPixelAccessToken()),
          catchError(err => {
            this._snackbarService.open(err?.error?.message ?? err.message ?? err, SnackBarType.error, 3000);
            return EMPTY;
          })
        )
        .subscribe()
    );
  }

  reloadPixelAccessToken(): void {
    this._subjectManager.next('reloadPixelAccessToken', true);
  }
}

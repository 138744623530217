<lib-searchable-select-control
  [control]="formControl"
  [searchableListItems]="items$ | async"
  [label]="field.props['label']">
</lib-searchable-select-control>
<span
  class="mat-mdc-form-field-error"
  role="alert"
  *ngIf="formControl.touched && field.props['required'] && !formControl.valid">
  <formly-validation-message [field]="field"></formly-validation-message>
</span>

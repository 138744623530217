import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { SearchableListItemWithSelect } from '@nimbus/shared-lib';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PixelManagerBuysideNetworkService } from '../../../services/pixel-manager-buyside-network.service';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Component({
  selector: 'pixel-manager-buy-side-input',
  templateUrl: './buyside-input.component.html',
  styleUrls: ['./buyside-input.component.scss'],
})
export class BuysideInputComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  items$: Observable<SearchableListItemWithSelect[]>;

  constructor(
    public ovs: ObjectValidatorService,
    public pixelManagerService: PixelManagerBuysideNetworkService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.items$ = this.pixelManagerService.buysideNetworks$.pipe(
      map(networks => networks.map(
          network =>
            new SearchableListItemWithSelect(
              network.id,
              false,
              network.buysideNetwork,
            ),
        ))
    );
  }
}

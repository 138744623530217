import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AccessControlService, ConfigService } from '@nimbus/shared-lib';
import { AuthenticationService } from '../authentication/services/authentication.service';
import { ConfigService as CS } from 'core-global-frontend-config';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { StartupSplashScreenService } from 'core-global-frontend-common-ui';
import { SyndicationWelcomeToNewUXComponent } from '../splash-screens/syndication-welcome-to-new-ux.component';
import { DomainCreatorServiceFactory } from '../services/domain-creator-cache-factory.service';
import { DomainConfigurationWebService } from '../../areas/domain/domain-configuration/services/domain-configuration-web.service';
import { PixelManagerBuysideNetworkService } from '../services/pixel-manager-buyside-network.service';

@Injectable({
  providedIn: 'root',
})
export class InitializerService {
  private _applicationId = 5;
  private _applicationName = 'Ramp Partner';

  constructor(
    _configService: ConfigService,
    _cs: CS,
    private _authenticationService: AuthenticationService,
    private _accessControlService: AccessControlService,
    _startupSplashScreenService: StartupSplashScreenService,
    _pixelManagerBuysideNetworkService: PixelManagerBuysideNetworkService,
    _domainConfigurationWebService: DomainConfigurationWebService,
    @Inject(OKTA_AUTH) public _oktaAuth: OktaAuth,
    private _domainCreatorServiceFactory: DomainCreatorServiceFactory | null
  ) {
    // Notes:
    // Debug API IDs have the format: Debug{apiId}{environmentName}
    // The API ID in the debug name should match exactly the non-debug name (case-sensitive).
    // You can have as many debug environments as you like: Localhost, Staging, Production, etc.
    // If there are multiple APIs per debug environment, use the same environmentName and they will be grouped together.
    // Leaving an API out of a particular environment will fall back to the default for that API only.
    // Examples:
    // DebugRampAPILocalhost, DebugRampPartnerAPIStaging
    _cs.init({
      schemaFolder: environment.schemaFolder,
      apis: environment.apis,
      envName: environment.name,
      applicationName: environment.applicationName,
      oktaConfig: null,
      primaryApiName: 'RampPartnerAPI',
      nimbusApiKey: environment.nimbusApiKey,
      host: environment.host
    });
    _configService.init(
      {
        RampPartnerAPI: { host: environment.apis['RampPartnerAPI'] },
        RampAPI: { host: environment.apis.RampAPI },
        debugApi: { host: environment.apis['debugApi'] },
        RetoolEmbedAPI: { host: environment.apis['RetoolEmbedAPI'] },
        PartnerAPI: { host: environment.apis['PartnerAPI'] },
        NotificationAPI: { host: environment.apis['NotificationAPI'] },
      },
      this._applicationId,
      this._applicationName,
      environment['log'],
      'RampPartnerAPI',
      {
        Users: { path: '/users' },
        Roles: { path: '/roles?applicationId={0}' },
        UserInfo: { path: '/users/id?userId={0}&includePermissions=true' },
        UsersByApplication: { path: '/users?applicationId={0}' },
        HealthMonitor: { path: '/health-monitor' },
      },
      environment['nimbusApiKey'],
      environment['googleTag'],
      environment['embedIds'],
    );
    this._authenticationService.init();
    this._domainCreatorServiceFactory.init();
    this._oktaAuth.authStateManager.subscribe(authState => {
      if (authState.isAuthenticated) {
        //this._oktaAuth.authStateManager is not a real observable and can't be piped. So we need to use subscriptions in subscriptions even if that is usually something to avoid.
        _domainConfigurationWebService.getTrafficSource().subscribe();
        this._accessControlService.resourcePermissions$.subscribe(() => {
          if (authState.isAuthenticated && this._accessControlService.canAccessByResource(SyndicationWelcomeToNewUXComponent.resource)) {
            this._oktaAuth.getUser().then(() => {
              _startupSplashScreenService.show('Google-Styling', SyndicationWelcomeToNewUXComponent, 1);
            });
          }
        });
      }
    });
  }
}

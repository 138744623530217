import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import {
  AngularMaterialModule,
  NimbusFormsModule,
} from 'core-global-frontend-common-ui';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Component({
  selector: 'app-edit-access-token-input',
  templateUrl: './edit-access-token-input.component.html',
  styleUrls: ['./edit-access-token-input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AngularMaterialModule,
    NimbusFormsModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class EditAccessTokenInputComponent implements AfterViewInit, OnDestroy {
  @ViewChild('accessTokenInput') input: ElementRef;
  private _strRegex = new RegExp(/^[a-z0-9]+$/i);
  private readonly _subscriptionManager = new SubscriptionManager();
  private _originalAccessToken: string;
  private _validAccessToken: string;
  tokenIsModified: boolean = false;
  @Input() position: number;
  @Input() token: string;
  @Output() cancelEdit: EventEmitter<void> = new EventEmitter<void>();
  @Output() editRequested: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private _ovs: ObjectValidatorService,
    private _snackbarService: SnackbarService,
  ) { }

  ngAfterViewInit(): void {
    this._originalAccessToken = this.token;
    this._validAccessToken = this.token;
    this.input.nativeElement.focus();
    if (!this._ovs.isNullOrEmpty(this.token)) {
      // Use setTimeout to ensure the focus happens first
      setTimeout(() => {
        this.input.nativeElement.setSelectionRange(0, this.input.nativeElement.value.length);
      }, 10);
    }
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
  }

  edit() {
    this.editRequested.emit(this.token);
  }

  onAccessTokenChange(event) {
    if (this._strRegex.test(event)) {
      this._validAccessToken = event;
    } else {
      this.input.nativeElement.value = this._validAccessToken;
      this.token = this._validAccessToken;
      this._snackbarService.open(
        'Only letter or number characters are allowed.',
        SnackBarType.warn,
        10000,
      );
    }
    this.tokenIsModified = this._originalAccessToken !== this.token;
  }

  cancel() {
    this.cancelEdit.emit();
  }
}

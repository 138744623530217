import { Inject, Injectable } from '@angular/core';
import {
  Observable,
  BehaviorSubject,
  combineLatest,
  defer,
  switchMap,
  catchError,
} from 'rxjs';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { Partner } from '../models/partner.model';
import {
  SubjectManager,
  SubscriptionManager,
} from '@nimbus/global-frontend-subscription-manager';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';
import { ImpersonationTrackerService } from './impersonation-tracker.service';

@Injectable()
export class PartnerCacheService {
  private _subscriptionManager: SubscriptionManager = new SubscriptionManager();
  private readonly _subjectManager = new SubjectManager();
  private readonly _loadingInProgressSubject = new BehaviorSubject<boolean>(false);
  readonly isLoading$: Observable<boolean> = this._loadingInProgressSubject.asObservable();
  readonly partners$: Observable<Partner[]>;
  readonly currentPartner$: Observable<Partner>;
  hasPartnerId: boolean = false;
  private readonly _preferred_username = 0;
  private readonly _impersonated_username = 1;
  partners: Partner[] = [];

  constructor(
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private _ovs: ObjectValidatorService,
    private _webServiceBuilderService: NimbusWebServiceBuilderService,
    private _impersonationTrackerService: ImpersonationTrackerService,
  ) {
    this._subjectManager.register('partners');
    this.partners$ = this._subjectManager.get('partners');
    this._subjectManager.register('currentPartner');
    this.currentPartner$ = this._subjectManager.get('currentPartner');
    this._loadingInProgressSubject.next(true);
    this._subscriptionManager.registerMultiple([
      combineLatest([
        defer(() => this._oktaAuth.getUser()),
        this._impersonationTrackerService.userInfo$,
      ])
        .pipe(
          switchMap(values =>
            this._webServiceBuilderService.builder
              .withApi('PartnerAPI')
              .withUrl('partners')
              .build()
              .get<any[]>({
                partnerEmail: this._ovs.isNullOrEmpty(
                  values[this._impersonated_username],
                )
                  ? values[this._preferred_username].preferred_username
                  : values[this._impersonated_username].email,
                types: 'syndication,distribution',
              }),
          ),
          catchError(error => {
            this._loadingInProgressSubject.next(false);
            throw error;
          }),
        )
        .subscribe(partners => {
          const partnerModels = partners.map(
            partner => new Partner(partner.partnerId, partner.partnerName, partner.domainDailyLimit, partner.domainAllTimeLimit)
          );
          this.partners = partnerModels;
          this.hasPartnerId = this._ovs.isNullOrEmpty(partnerModels);
          this._subjectManager.next('partners', partnerModels);
          this._loadingInProgressSubject.next(false);
        }),
      this.partners$.subscribe(partners => {
        if (this._ovs.isArray(partners) && partners.length > 0)
          this._subjectManager.next('currentPartner', partners[0]);
      })
    ]);
  }

  setCurrentPartner(partnerId: number) {
    const partner = this.partners.find(p => p.id === partnerId);
    if (!partner) return;
    this._subjectManager.next('currentPartner', partner);
  }
}

import { Component } from '@angular/core';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Component({
  selector: 'app-ad-network-pixel-tracking',
  templateUrl: './ad-network-pixel-tracking.component.html',
  styleUrls: [
    './ad-network-pixel-tracking.component.scss',
    '../documentation.scss',
  ],
})
export class AdNetworkPixelTrackingComponent {

  boldParameters(text: string, data: { parameter: string }[]): string {
    if (!text || !data || data.length === 0) {
      return text;
    }

    const keywords = data.map(item => item.parameter);
    const escapedKeywords = keywords.map(keyword => keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
    const regex = new RegExp(`\\b(${escapedKeywords.join('|')})\\b`, 'gi');
    return text.replace(regex, '<span class="documentation bold">$1</span>');
  }

  adNetworks = [
    'Facebook',
    'Google Display Network (GDN)',
    'Taboola',
    'Outbrain',
    'Snapchat',
    'TikTok',
    'Pangle (same setup as TikTok)',
    'Zemanta',
    'MediaGo',
    'NewsBreak',
  ];
  pixels = [
    {
      name: 'Facebook Pixel Helper',
      link: 'https://chrome.google.com/webstore/detail/facebook-pixel-helper/fdgfkebogiimcoedlicjlajpkdmockpc?hl=en',
    },
    {
      name: 'Taboola Pixel Helper',
      link: 'https://chrome.google.com/webstore/detail/taboola-pixel-helper/aefiepimkogajhddmhcekceihikjcabd?hl=en',
    },
    {
      name: 'Outbrain Pixel Tracker',
      link: 'https://chrome.google.com/webstore/detail/outbrain-pixel-tracker/daebadnaphbiobojnpgcenlkgpihmbdc?hl=en',
    },
    {
      name: 'Snap Pixel Helper',
      link: 'https://chrome.google.com/webstore/detail/snap-pixel-helper/hnlbfcoodjpconffdbddfglilhkhpdnf?',
    },
    {
      name: 'TikTok Pixel Helper',
      link: 'https://chrome.google.com/webstore/detail/tiktok-pixel-helper/aelgobmabdmlfmiblddjfnjodalhidnn?hl=en',
    },
  ];
  table = {
    headers: ['Parameter', 'Description', 'Example', 'Where It\'s Used'],
    data: [
      {
        header: 'Facebook Pixel',
        subHeader:
          'To integrate with our FB tracking solutions. You must add your pixels and ' +
          'corresponding API access tokens in the S2S Pixel Manager in RAMP Partner',
        required: 'fbid and at least one event name (fbland, fbserp, fbclick)',
        records: [
          {
            parameter: 'fbid',
            description: 'The ID of the Facebook pixel',
            example: '=1234556748',
            where: 'PIXEL + SERVER',
          },
          {
            parameter: 'fbland',
            description:
              'The percent-encoded name of the conversion event you ' +
              'would like to fire when the user reaches the System1 ' +
              'campaign lander, spaces substituted with a ‘+’ character',
            example: '=View+Content',
            where: 'PIXEL + SERVER (deduplicated)',
          },
          {
            parameter: 'fbserp',
            description:
              'The percent-encoded name of the conversion event you ' +
              'would like to fire when a user executes a search, spaces ' +
              'substituted with a ‘+’ character',
            example: '=Search',
            where: 'PIXEL + SERVER (deduplicated)',
          },
          {
            parameter: 'fbclick',
            description:
              'The percent-encoded name of the conversion event you ' +
              'would like to fire when a user monetizes with an ' +
              'advertiser',
            example: '=Click',
            where: 'PIXEL + SERVER (deduplicated)',
          },
          {
            parameter: 'fbclid',
            description:
              'The click ID that Facebook adds to destination URLs. ' +
              'This is a required parameter in order to launch our S2S ' +
              'integration',
            example: '=IwAR0_ZoLApAV3KbGrveMa38rptuB_v9zrbo-z7ToY7aofROkIvHBh',
            where: 'PIXEL + SERVER',
          },
        ],
      },
      {
        header: 'Google Ads Pixel',
        required: 'gamid',
        records: [
          {
            parameter: 'gamid',
            description: 'Google Ads Measurement ID for your account',
            example: '=AW-123456789',
            where: 'PIXEL',
          },
          {
            parameter: 'glcid',
            description:
              'The conversion ID associated with the Google Ads ' +
              'conversion you would like to fire on the lander',
            example: '=AW-123456789/AbC-D_dfG-h12_34-567',
            where: 'PIXEL',
          },
          {
            parameter: 'gcid',
            description:
              'The conversion ID associated with the Google Ads conversion ' +
              'you would like to fire on the SERP. This is in addition to, or separate from, ' +
              'the prior glcid parameter',
            example: '=AW-123456789/AbC-D_dfG-h12_34-567',
            where: 'PIXEL',
          },
          {
            parameter: 'gclcid',
            description:
              'Conversion ID associated with the Google Ads conversion you would like to fire ' +
              'when a user monetizes with an advertiser',
            example: '=AW-123456789/AbC-D_efG-h 12_34-567',
            where: 'PIXEL',
          },
          {
            parameter: 'ga4mid',
            description:
              'Optional - an additional Property ID to measure page views and events',
            example: '=G-123456789',
            where: 'PIXEL',
          },
        ],
      },
      {
        header: 'Taboola Pixel',
        required: 
        'tbid or tbclickid and at least one event name (tbland, tbserp, tbclick). ' +
        'If tbid is provided, the events will be tracked client-side via Pixel. ' +
        'If tbclickid is provided, the events will be tracked server-side via S2S endpoint. ' +
        'If both IDs are provided, preference is given to tbclickid and the event is tracked server-side.',
        records: [
          {
            parameter: 'tbid',
            description: 'The ID of the Taboola pixel',
            example: '=1234556748',
            where: 'PIXEL',
          },
          {
            parameter: 'tbland',
            description:
              'The name of the conversion event you would like to fire ' +
              'when the user reaches the System1 campaign lander, ' +
              'spaces substituted with a ‘+’ character',
            example: '=Land',
            where: 'PIXEL + SERVER',
          },
          {
            parameter: 'tbserp',
            description:
              'The name of the conversion event you would like to fire ' +
              'when a user executes a search, spaces substituted with a ' +
              '‘+’ character',
            example: '=Search',
            where: 'PIXEL + SERVER',
          },
          {
            parameter: 'tbclick',
            description:
              'The name of the conversion event you would like to fire ' +
              'when a user monetizes with an advertiser',
            example: '=Purchase',
            where: 'PIXEL + SERVER',
          },
          {
            parameter: 'tbclickid',
            description:
              'Taboola click ID assigned to the user. This is available on ' +
              'the Taboola platform as the macro {click_id}',
            example: '={click_id}',
            where: 'SERVER',
          },
        ],
      },
      {
        header: 'Outbrain Pixel',
        required:
          'obid or obclickid and at least one event name (obland, obserp, obclick). ' +
          'If obid is provided, the events will be tracked client-side via Pixel. ' +
          'If obclickid is provided, the events will be tracked server-side via S2S endpoint. ' +
          'If both IDs are provided, preference is given to obclickid and the event is tracked server-side.',
        records: [
          {
            parameter: 'obid',
            description: 'The ID of the Outbrain pixel',
            example: '=1234567890',
            where: 'PIXEL',
          },
          {
            parameter: 'obland',
            description:
              'The name of the conversion event you would like to fire ' +
              'when the user reaches the System1 campaign lander, ' +
              'spaces substituted with a ‘+’ character',
            example: '=Land',
            where: 'PIXEL + SERVER',
          },
          {
            parameter: 'obserp',
            description:
              'The name of the conversion event you would like to fire ' +
              'when a user executes a search, spaces substituted with a ' +
              '‘+’ character',
            example: '=Search',
            where: 'PIXEL + SERVER',
          },
          {
            parameter: 'obclick',
            description:
              'The name of the conversion event you would like to fire ' +
              'when a user monetizes with an advertiser',
            example: '=Purchase',
            where: 'SERVER',
          },
          {
            parameter: 'obclickid',
            description:
              'The Outbrain click ID assigned to the user. This is ' +
              'available on the Outbrain platform as the macro ' +
              '{{ob_click_id}}',
            example: '={{ob_click_id}}',
            where: 'SERVER',
          },
        ],
      },
      {
        header: 'Snapchat Pixel',
        subHeader:
          'To integrate with our Snapchat tracking solutions. You must add your pixels ' +
          'and corresponding API access tokens in the S2S Pixel Manager in Ramp Partner',
        required: 'scid and at least one event name (scland, scserp, scclick)',
        records: [
          {
            parameter: 'scid',
            description: 'The ID of the Snapchat pixel',
            example: '=1abc-2def',
            where: 'PIXEL + SERVER',
          },
          {
            parameter: 'scland',
            description:
              'The name of the conversion event you would like ' +
              'to fire when the user reaches the System1 campaign lander, ' +
              'spaces substituted with a ‘+’ character',
            example: '=PAGE_VIEW',
            where: 'PIXEL + SERVER (deduplicated)',
          },
          {
            parameter: 'scserp',
            description:
              'The name of the conversion event you would like ' +
              'to fire when a user executes a search, spaces substituted ' +
              'with a ‘+’ character',
            example: '=PURCHASE',
            where: 'PIXEL + SERVER (deduplicated)',
          },
          {
            parameter: 'scclick',
            description:
              'Name of the conversion event to fire when a user monetizes with ' +
              'an advertiser ((requires server to server setup with your ' +
              'System1 account manager)',
            example: '=PURCHASE',
            where: 'SERVER',
          },
        ],
      },
      {
        header: 'Zemanta Pixel',
        required: 'zmid, zmclickid and at least one event name (zmland, zmserp, zmclick)',
        records: [
          {
            parameter: 'zmid',
            description:
              'The ID of the Zemanta pixel. Take 114567 from postback URL on Zemanta UI. ' +
              'Example: https://p1.zemanta.com/v2/p/s2s/114567/Click/?postbackid={postbackid}, ' +
              'and use it in the campaign URL: zmid=114567',
            example: '=123456',
            where: 'SERVER',
          },
          {
            parameter: 'zmland',
            description:
              'Name of the conversion event to fire when the user reaches the System1 campaign lander',
            example: '=PageView',
            where: 'SERVER',
          },
          {
            parameter: 'zmserp',
            description:
              'Name of the conversion event to fire when a user executes a search',
            example: '=Add+To+Wishlist',
            where: 'SERVER',
          },
          {
            parameter: 'zmclick',
            description:
              'Name of the conversion event to fire when a user monetizes with an advertiser',
            example: '=Purchase',
            where: 'SERVER',
          },
          {
            parameter: 'zmclickid',
            description:
              'The Zemanta click ID assigned to the user. This is available on the ' +
              'Zemanta platform as the macro {postbackid}',
            example: '={postbackid}',
            where: 'SERVER',
          },
        ],
      },
      {
        header: 'TikTok Pixel',
        subHeader:
          'To integrate with our Tiktok tracking solutions. You must add your pixels and corresponding ' +
          'API access tokens in the S2S Pixel Manager in Ramp Partner',
        required: 'ttid and ttclid and at least one event name (ttland, ttserp, ttclick)',
        records: [
          {
            parameter: 'ttid',
            description: 'The ID of the TikTok pixel',
            example: '=1234567890',
            where: 'PIXEL + SERVER',
          },
          {
            parameter: 'ttland',
            description:
              'Name of the conversion event to fire when the user ' +
              'reaches the System1 campaign lander',
            example: '=PageView',
            where: 'PIXEL + SERVER (deduplicated)',
          },
          {
            parameter: 'ttserp',
            description:
              'Name of the conversion event to fire when a user ' +
              'executes a search',
            example: '=ClickButton',
            where: 'PIXEL + SERVER (deduplicated)',
          },
          {
            parameter: 'ttclick',
            description:
              'Name of the conversion event to fire when a user ' +
              'monetizes with an advertiser (requires server to server ' +
              'setup with your System1 account manager)',
            example: '=CompletePayment',
            where: 'SERVER',
          },
          {
            parameter: 'ttclid',
            description:
              'TikTok click ID assigned to the user. You can pass this ' +
              'dynamically through Tiktok using __CLICKID__',
            example: '=__CLICKID__',
            where: 'PIXEL + SERVER',
          },
        ],
      },
      {
        header: 'MediaGo Pixel',
        required: 'mgid, mgclickid and at least one event name (mgclick)',
        records: [
          {
            parameter: 'mgid',
            description: 'MediaGo Pixel ID (Mediago calls it tracking ID in their docs)',
            example: '=1234567',
            where: 'SERVER',
          },
          {
            parameter: 'mgclickid',
            description: 'MediaGo click ID',
            example: '=${TRACKING_ID}',
            where: 'SERVER',
          },
          {
            parameter: 'mgclick',
            description:
              'Name of the conversion event to fire when a user monetizes with an advertiser',
            example: '=Purchase',
            where: 'SERVER',
          },
        
        ],
      },
      {
        header: 'Newsbreak Pixel',
        required: 
          'nbclickid, and at least one event name (nbclick, nbserp, or nbland) ' +
          'Note: there is no pixel id required for Newsbreak',
        records: [
          {
            parameter: 'nbclickid',
            description: 'Newsbreak click id (Newsbreak calls it the callback parameter in their doc)',
            example: '=__CALLBACK_PARAM__',
            where: 'SERVER',
          },
          {
            parameter: 'nbland',
            description: 'Name of the conversion event to fire when the user reaches the System1 campaign lander',
            example: '=landloaded',
            where: 'SERVER',
          },
          {
            parameter: 'nbserp',
            description:
              'Name of the conversion event to fire when a user executes a search',
            example: '=serploaded',
            where: 'SERVER',
          },
          {
            parameter: 'nbclick',
            description:
              'Name of the conversion event to fire when a user clicks on ads on our serp and thus monetizes with an advertiser.',
            example: '=complete_payment',
            where: 'SERVER',
          },
        
        ],
      },
    ],
  };

  constructor(public ovs: ObjectValidatorService) {}

  getColumnHeaderClass(table, index) {
    return index < table.headers.length - 1
      ? 'documentation-grid column-header column-header__standard'
      : 'documentation-grid column-header column-header__end';
  }

  getColumnValueClass(columnIndex, table) {
    return columnIndex < table.headers.length - 1
      ? 'documentation-grid column-value column-value__standard'
      : 'documentation-grid column-value column-value__end';
  }
}

import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { DomainListCacheService } from './services/domain-list-cache.service';
import { DomainListColumnsBuilderService } from './services/domain-list-columns-builder.service';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { DomainConfigurationComponent } from '../domain-configuration/domain-configuration.component';
import { DomainConfigurationService } from '../domain-configuration/services/domain-configuration.service';
import { PartnerCacheService } from '../../../shared/services/partner-cache.service';
import { DomainCreateLimiterService } from '../domain-creator/services/domain-create-limiter.service';
import {
  AccessControlService,
  BasePageComponent,
  DockContent,
  DockableItem,
  FileType,
  ILayoutContent,
  ObjectValidatorService,
} from '@nimbus/shared-lib';
import { ColumnInfo, GridComponent } from 'core-global-frontend-grid';
import { HeaderService } from 'core/libs/global/frontend/header/src/lib/header.service';
import { DomainManagerPrefilterModel } from '../models/domain-manager-prefilter.model';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { GridModel } from 'core/libs/global/frontend/grid/src/lib/grid-models/grid-model';
import { FileDownloadService, NumberRange } from '@nimbus/grid';
import { FileFromData } from 'core/libs/global/frontend/grid/src/lib/grid-services/file-from-data.service';

@Component({
  selector: 'app-domain-manager',
  templateUrl: './domain-manager.component.html',
  styleUrls: ['./domain-manager.component.scss'],
})
export class DomainManagerComponent
  extends BasePageComponent
  implements AfterViewInit, OnDestroy, ILayoutContent {
  columns: ColumnInfo[];
  downloadFileType: FileType = FileType.CSV;
  private readonly _filterSubject =
    new ReplaySubject<DomainManagerPrefilterModel>();
  filter$ = this._filterSubject.asObservable();
  @Input() set filter(filter: DomainManagerPrefilterModel) {
    this.filterChanged(filter);
    this._filterSubject.next(filter);
  }
  readonly filteredDomains$ = combineLatest([
    this.domainListCacheService.filteredDomains$,
    this.filter$,
  ]).pipe(
    map(([domains, filter]) =>
      domains
    ),
  );
  private pageOptionsSubject = new BehaviorSubject<PageEvent>(undefined);
  private pageSortSubject = new BehaviorSubject<Sort>(undefined);
  private pageFilterSubject = new BehaviorSubject<DomainManagerPrefilterModel>(undefined);
  pageOptions$ = this.pageOptionsSubject.asObservable();
  private _pageSort$ = this.pageSortSubject.asObservable();
  pageFilter$ = this.pageFilterSubject.asObservable();
  dataLength$: Observable<number> = combineLatest([
    this.pageOptions$,
    this._pageSort$,
    this.pageFilter$,
  ])
    .pipe(
      switchMap(([options, sort, filter]) =>
        this.domainListCacheService.load(
          true,
          options?.pageIndex,
          options?.pageSize,
          sort,
          filter
        )
      ),
      map(response => response[0].pages * response[0].parkedDomains.length)
    );
  @ViewChild(GridComponent) gridComponent!: GridComponent;
  gridModel$: Observable<GridModel> = combineLatest([
    this.filteredDomains$,
    this.dataLength$
  ])
    .pipe(
      filter(([domains, dataLength]) => !!domains && !!dataLength),
      distinctUntilChanged((p, c) => p[1] === c[1] && JSON.stringify(p[0]) === JSON.stringify(c[0])),
      map(([domains, dataLength]) => new GridModel("domains", domains, { active: "createdAt", direction: "desc" }, dataLength))
    );

  constructor(
    public partnerCacheService: PartnerCacheService,
    public domainListCacheService: DomainListCacheService,
    public ovs: ObjectValidatorService,
    public domainListColumnsBuilderService: DomainListColumnsBuilderService,
    private _domainConfigurationService: DomainConfigurationService,
    private _accessControlService: AccessControlService,
    private _domainCreateLimiterService: DomainCreateLimiterService,
    private _headerService: HeaderService,
    private _fileDownloadService: FileDownloadService,
  ) {
    super();
    this._domainCreateLimiterService.clearDomains();
    this._headerService.setSubheading('Launch net new domains with us.');
    this._subscriptionManager.registerMultiple([
      combineLatest([
        this.partnerCacheService.partners$,
        of(
          this._accessControlService.canAccessBySelector(
            'keyword-method-authorization',
          ),
        ),
      ])
        .pipe(
          tap(([partners, keywordAccess]) => {
            this.columns = domainListColumnsBuilderService
              .buildColumns(partners.length > 1, keywordAccess)
              .filter(column => !!column.field && column.field !== 'nA');
          }),
        )
        .subscribe(),
      this.filteredDomains$.subscribe(),
    ]);
  }

  refresh() {
    this.pageFilterSubject.next(this.pageFilterSubject.getValue());
  }

  async ngAfterViewInit(): Promise<void> {
    super.ngAfterViewInit('domain/manager');
    this.setDockContent(
      new DockContent(
        [new DockableItem('Details', DomainConfigurationComponent)],
        'id',
      ),
    );
    this._setupSubscriptions();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._domainConfigurationService.closeConfigurationDock();
  }

  private _setupSubscriptions() {
    this._subscriptionManager.registerMultiple([
      this._domainConfigurationService.closeConfigurationDock$
        .pipe(tap(() => this.closeDock()))
        .subscribe(),
      combineLatest([
        this._domainConfigurationService
          .openDock$()
          .pipe(switchMap(() => this._domainConfigurationService.domainId$)),
      ])
        .pipe(filter(value => !this.ovs.isNullOrEmpty(value[0])))
        .subscribe(value => this.setDockModel(value[0])),
      this.domainListCacheService.load(true).subscribe(),
    ]);
  }

  pageChanged(pageEvent: PageEvent) {
    this.pageOptionsSubject.next(pageEvent);
  }

  sortChanged(sort: Sort) {
    this.pageOptionsSubject.next({ pageIndex: 0, pageSize: this.pageOptionsSubject.value?.pageSize || 20, length: this.pageOptionsSubject.value?.length });
    this.pageSortSubject.next(sort);
  }

  filterChanged(filter: DomainManagerPrefilterModel) {
    if (filter != null) {
      this.pageOptionsSubject.next({ pageIndex: 0, pageSize: this.pageOptionsSubject.value?.pageSize || 20, length: this.pageOptionsSubject.value?.length });
      this.pageFilterSubject.next(filter);
      this.gridComponent.resetServerPageIndex();
    }
  }

  downloadAll() {
    combineLatest([
      this._pageSort$,
      this.pageFilter$,
    ])
      .pipe(
        switchMap(([sort, filter]) =>
          this.domainListCacheService.loadAll(
            sort,
            filter
          )
        ),
        take(1),
        tap(model => {
          console.log(model);
          this._fileDownloadService.provideFile(
            new FileFromData().getCsv(
              {
                model: model,
                columns: this.columns,
                pageSize: 0,
              },
              new NumberRange(0, model.length - 1) as any,
            ),
            'data.csv',
            this.downloadFileType,
          )
        })
      ).subscribe();
  }
}
